import React, { useState, useEffect } from "react";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MuiAlert from '@mui/material/Alert';

export default function AddDocument({ onDocumentAdded }) {
  const [open, setOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const activeAccount = instance.getActiveAccount();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedTier("");
    setDescription("");
    setFileType("");
    setOpen(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTierChange = (event) => {
    setSelectedTier(event.target.value);
    setFileType(""); // Reset file type when tier changes
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const tierOptions = [
    {
      value: "Design Docs",
      label: "Design Docs",
      tierName: "designDocOptions",
    },
    {
      value: "Customer Docs",
      label: "Customer Docs",
      tierName: "customerDocOptions",
    },
    {
      value: "Resource Validation Docs",
      label: "Resource Validation Docs",
      tierName: "resourceValidationDocOptions",
    },
    {
      value: "FM - CFRP Docs",
      label: "FM - CFRP Docs",
      tierName: "fmCfrpDocOptions",
    },
    {
      value: "FM - Install Docs",
      label: "FM - Install Docs",
      tierName: "fmInstallDocOptions",
    },
    {
      value: "FM - Planning Docs",
      label: "FM - Planning Docs",
      tierName: "fmPlanningDocOptions",
    },
  ];

  const fileOptions = {
    designDocOptions: [
      { value: "Preliminary Design", label: "Preliminary Design" },
      { value: "Minor Change", label: "Minor Change" },
      { value: "Major Change", label: "Major Change" },
    ],
    customerDocOptions: [
      { value: "Contract", label: "Contract" },
      { value: "Customer Quote", label: "Customer Quote" },
      { value: "Vendor Bill", label: "Vendor Bill" },
    ],
    resourceValidationDocOptions: [
      { value: "Internet Validation", label: "Internet Validation" },
      { value: "Pad Validation", label: "Pad Validation" },
      { value: "Power Validation", label: "Power Validation" },
    ],
    fmCfrpDocOptions: [
      { value: "CFRP Enclosure QC Form", label: "CFRP Enclosure QC Form" },
      { value: "CFRP Testing", label: "CFRP Testing" },
    ],
    fmInstallDocOptions: [
      { value: "Install Change Order/As Built", label: "Install Change Order/As Built" },
      { value: "Install Final Test (LV)", label: "Install Final Test (LV)" },
      { value: "Install Final Test (Teamlead)", label: "Install Final Test (Teamlead)" },
    ],
    fmPlanningDocOptions: [
      { value: "Pre Shipment Checklist", label: "Pre Shipment Checklist" },
      { value: "Network Switch Map", label: "Network Switch Map" },
      { value: "SI Information Form", label: "SI Information Form" },
    ],
  };

  const handleSave = () => {
    const userName = name;
    const isoFormattedDate = new Date().toISOString();

    const formData = new FormData();
    formData.append("Dscrption", description);
    formData.append("CsId", "");
    formData.append("ProjectId", searchParams.get("id"));
    formData.append("DocumentLink", "");
    formData.append("UserName", userName);
    formData.append("FileType", fileType);
    formData.append("VersionNo", "");
    formData.append("DateTime", isoFormattedDate);
    formData.append("DocumentFile", selectedFile);

    setLoading(true);

    axios.post("/api/Onboarding/uploadDocument", formData, {
    })
      .then(() => {
        setSnackbarOpen(true);
        onDocumentAdded();
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error uploading document. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Button onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add Document
          </Typography>

          <TextField
            select
            label="Select Tier"
            value={selectedTier}
            onChange={handleTierChange}
            style={{ width: "100%", paddingBottom: "20px" }}
          >
            {tierOptions.map((option) => (
              <MenuItem key={option.value} value={option.tierName}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {selectedTier && (
            <>
              <TextField
                select
                label="File Type"
                value={fileType}
                onChange={handleFileTypeChange}
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                {fileOptions[selectedTier]?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <input type="file" onChange={handleFileChange} style={{ width: "100%", paddingBottom: "20px" }} />
            </>
          )}

          <TextField
            multiline
            label="Enter Description"
            value={description}
            onChange={handleDescriptionChange}
            style={{ width: "100%", paddingBottom: "20px" }}
          />

          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}

            <Button style={{ color: "green" }} onClick={handleSave} disabled={loading || !fileType}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Document saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}


